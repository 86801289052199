import { Stack } from '@chakra-ui/react';
import { HeroImageProps, src } from '@/src/components/UI/HeroImageProps';
import Image from 'next/image';
import Head from 'next/head';

const Images: { [key in src]: string } = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-home-mobile-v-2.webp`,
  Sell: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-sell-mobile.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-aboutus-mobile-v-2.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-careers-mobile-v-2.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-agents-mobile-v-3.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/mobile/largeImages/hero-img-mobile/hero-abroad-mobile.webp`,
};
const HeroImage = ({ src, alt, text, action, mask = false }: HeroImageProps) => {
  return (
    <Stack w={'100%'} position={'relative'}>
      <Head>
        <link rel='preload' href={Images[src]} as='image' />
      </Head>
      {mask && <div className={'absolute top-0 left-0 w-full h-full bg-black z-10 bg-opacity-25'} />}
      <Image
        priority
        src={Images[src]}
        objectFit={'contain'}
        className={'bg-gray300'}
        layout={'responsive'}
        width={414}
        height={580}
        alt={alt}
        blurDataURL={
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNUrAcAAMcAouVPGYoAAAAASUVORK5CYII='
        }
        placeholder={'blur'}
        loading={'eager'}
      />
      {text ? (
        <div className={'absolute flex justify-center items-center w-full h-full top-0 z-20'}>
          <p className='text-4xl leading-normal text-center font-medium uppercase text-white'>主導自己的未來</p>
        </div>
      ) : null}
      {action}
    </Stack>
  );
};
export default HeroImage;
