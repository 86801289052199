import React, { ReactNode } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

import FullPage from '@/src/components/UI/FullPageImpl';
import HeaderProps from '@/src/components/UI/HeaderType';

interface Props extends HeaderProps {
  children: ReactNode;
  hasPadding: boolean;
  title: string;
  text: string;
  mobileFlexProps?: FlexProps;
}

const MobileFullPage = React.forwardRef<HTMLDivElement, Props>(
  ({ children, hasPadding = true, mobileFlexProps = {}, ...props }, ref) => {
    const px = hasPadding ? { base: 4, sm: 0 } : 0;
    return (
      <FullPage {...props} ref={ref}>
        <Flex direction='column' px={px} {...mobileFlexProps}>
          {children}
        </Flex>
      </FullPage>
    );
  }
);

export default MobileFullPage;
